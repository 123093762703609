import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typewriter } from "react-simple-typewriter";
import particleOption from "../data/particle-config.json";
import Particles from "react-tsparticles";
import Bg1 from "../images/bg.jpg";
import Bg2 from "../images/bg2.jpg";
import Bg3 from "../images/bg3.jpg";
import Bg4 from "../images/bg4.jpg";
import Bg5 from "../images/bg5.jpg";
import Bg6 from "../images/bg6.jpg";
import Bg7 from "../images/bg7.jpg";
import Bg8 from "../images/bg8.jpg";
import Bg9 from "../images/bg9.jpg";
import Bg10 from "../images/bg10.jpg";
import Bg11 from "../images/bg11.jpg";
import Bg12 from "../images/bg12.jpg";
import video_1 from "../video/intro_1.mp4";
import { overlayAnimation } from "../App";
import Overlay from "./Overlay";

const images = [Bg1, Bg2, Bg3, Bg4, Bg5, Bg6, Bg7, Bg8, Bg9, Bg10, Bg11, Bg12]; // Add more images as needed

const Hero = ({ type = "bgColor" }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    overlayAnimation();

    if (type === "bgImage") {
      const interval = setInterval(() => {
        setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
      }, 5000); // Change image every 5 seconds

      return () => clearInterval(interval);
    }
  }, [type]);

  return (
    <>
      <Overlay />
      <HomeStyled>
        {type === "bgParticles" && (
          <Particles id="tsparticles" options={particleOption} />
        )}

        <div
          className="card-inner card-started active"
          id="home-card"
          style={{ height: "100vh" }}
        >
          {type === "bgParticles" && (
            <div className="slide" style={{ backgroundImage: `url(${images[currentImage]})` }}></div>
          )}

          {type === "bgImage" && (
            <div className="slide" style={{ backgroundImage: `url(${images[currentImage]})` }}></div>
          )}

          {type === "bgvideo" && (
            <div id="video-bg" className="slide">
              <div className="video_container">
                <video className="videoTag" autoPlay loop muted>
                  <source src={video_1} type="video/mp4" />
                </video>
              </div>
            </div>
          )}

          {type === "bgColor" && (
            <div className="slide" style={{ backgroundColor: "#000" }}></div>
          )}

          <div className="centrize full-width">
            <div className="vertical-center">
              <div className="title">
                <span>Steve</span> Tran
              </div>
              <div
                className="subtitle"
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                I am a
                <span style={{ color: "#fff" }}>
                  <Typewriter
                    words={[
                      "Hacker",
                      "Magician",
                      "Media Exec",
                      "Investor",
                      "Teacher",
                      "Public Servant",
                      "Podcaster",
                    ]}
                    loop={5}
                    cursor
                    cursorStyle="_"
                    typeSpeed={70}
                    deleteSpeed={50}
                    delaySpeed={2000}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </HomeStyled>
    </>
  );
};

export default Hero;

const HomeStyled = styled.div`
  .slide {
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: background-image 1s ease-in-out;
  }
  .video_container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
  }
  .videoTag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`;
